import PropTypes from 'prop-types';

const CashierFormView = ({
  session,
  setLoadingPaymentMethod,
  form,
  canLoadForm,
}) => !!session?.success && canLoadForm && (
  <div className="asia_cashier__form">
    <form
      action={session.url}
      method="post"
      target="cashier-frame"
      ref={form}
    >
      {Object.entries(session.parameters).map(([name, value]) => (
        <input type="hidden" name={name} value={value} key={name} />
      ))}
    </form>
    <iframe
      className={`cashier-frame cashier_${session.method}`}
      title="cashier_iframe"
      name="cashier-frame"
      frameBorder={0}
      scrolling="auto"
      seamless
      onLoad={() => setLoadingPaymentMethod(false)}
    />
  </div>
);

CashierFormView.defaultProps = {
  session: null,
};

CashierFormView.propTypes = {
  session: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object).isRequired,
  canLoadForm: PropTypes.bool.isRequired,
  setLoadingPaymentMethod: PropTypes.func.isRequired,
};

export default CashierFormView;
