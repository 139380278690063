import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { WITHDRAW } from 'CashierCommon/helpers';
import { fetchWithdraws, setCurrentOperation } from 'AsiaCashier/redux/thunks';
import {
  getPaymentMethods,
  getSelectedPaymentMethod,
  getCurrentOperation,
  getLoadingSession,
} from 'AsiaCashier/redux/selectors';
import withOperation from 'AsiaCashier/hocs/with_operation';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import WithdrawContainer from './withdraw_container';

const WithdrawModal = (props) => {
  const { selectedPaymentMethod } = props;
  const backUrl = `bv_cashier/${selectedPaymentMethod ? WITHDRAW : 'overview'}`;

  return withCashierModal({ tKey: WITHDRAW, backUrl })(WithdrawContainer)(props);
};

const mapStateToProps = createStructuredSelector({
  loading: getLoadingSession,
  paymentMethods: getPaymentMethods,
  currentOperation: getCurrentOperation,
  selectedPaymentMethod: getSelectedPaymentMethod,
});

export default connect(mapStateToProps, { fetchWithdraws, setCurrentOperation })(
  withOperation(WithdrawModal),
);
