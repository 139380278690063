import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DEPOSIT } from 'CashierCommon/helpers';
import { fetchDeposits, setCurrentOperation } from 'AsiaCashier/redux/thunks';
import {
  getCurrentOperation,
  getPaymentMethods,
  getSelectedPaymentMethod,
  getLoadingSession,
} from 'AsiaCashier/redux/selectors';
import withOperation from 'AsiaCashier/hocs/with_operation';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import DepositContainer from './deposit_container';

const DepositModal = (props) => {
  const { selectedPaymentMethod } = props;
  const backUrl = `bv_cashier/${selectedPaymentMethod ? DEPOSIT : 'overview'}`;

  return withCashierModal({ tKey: DEPOSIT, backUrl })(DepositContainer)(props);
};

const mapStateToProps = createStructuredSelector({
  loading: getLoadingSession,
  paymentMethods: getPaymentMethods,
  currentOperation: getCurrentOperation,
  selectedPaymentMethod: getSelectedPaymentMethod,
});

export default connect(mapStateToProps, { fetchDeposits, setCurrentOperation })(
  withOperation(DepositModal),
);
